import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
export default [
  {
    name: indexniDiYCWjuTMeta?.name ?? "index___it",
    path: indexniDiYCWjuTMeta?.path ?? "/it",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index___jp",
    path: indexniDiYCWjuTMeta?.path ?? "/jp",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index___en___default",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index___en",
    path: indexniDiYCWjuTMeta?.path ?? "/en",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  }
]